import { ArcwareApplication, ArcwareInit, ConnectOverlay, PixelStreaming } from '@arcware-cloud/pixelstreaming-websdk';
import axios from "axios";
import { useState,useEffect, useRef } from "react";
import {
  Participant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomEvent,
  
  Track,
} from 'livekit-client';
import {useParticipants,useChat,useEnsureRoom,AudioConference,LiveKitRoom,useRoomInfo} from "@livekit/components-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import MapComponent from '../components/MapComponent';
import DiscoverScreen from "./Discover";
import {GoScreenFull} from "react-icons/go";
import {HiSpeakerXMark} from  "react-icons/hi2";
import { MdPeople } from "react-icons//md"
import { ButtonGroup,ListGroup,Modal ,Card,Form,InputGroup,Toast,ToastContainer,ToastBody,ToastHeader  } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import {IoMdChatbubbles, IoIosMic, IoIosMicOff, IoMdContract} from "react-icons/io";
import {BsFillMicFill, BsFillEmojiSmileFill,BsMap, BsFillVolumeUpFill, BsSend} from "react-icons/bs";
import{FaMicrophone, FaUsers, FaMicrophoneSlash, FaAngleLeft} from "react-icons/fa";
import {BiSolidMap,BiHelpCircle} from "react-icons/bi";
import HelpUI from "./HelpUI";
import LoadingScreen from "./LoadingScreen";
import {MdClose} from 'react-icons/md'
import {FaArrowRightToCity} from 'react-icons/fa6';
import "./home.css"
import { useMeasure } from "@uidotdev/usehooks";
import useMessage from "antd/es/message/useMessage";
import { Network } from 'alchemy-sdk';
var isMobile = require('detect-touch-device');
let shareIdError = true;
let webrtcA;
let partName;
let activeRoom;
let emojiNumber;
let arcwareVideoId = 'streamingVideoContainer';
let arcwareAudioId = "streamingVideo"
let videoPaused = false;
let showMapButton;
let mapMessage;
let mobScrollHeight;
let newUnitSceneData = [];
function VoiceMuteUnMuteAll({participants}){


  
  
    const [muteBtn,setMuteBtn] = useState(false);
  
  
    var AllParticipants = Array.from(participants);
    var newAllParticipants =  AllParticipants.filter(e => e instanceof RemoteParticipant);
     
     let tracks;
       for (let i=0; i<newAllParticipants.length; i++){
     
          tracks = Array.from(newAllParticipants[i].tracks.values());
         }
     
     const toogleRemoteVoice=()=>{
    
      if(tracks.length>0){
    
        for(let i=0; i<tracks.length; i++){
      
        
          let voiceEnabled = tracks[i].disabled;
          
        if(voiceEnabled == false){
          tracks[i].setEnabled(false);
          setMuteBtn(true);
        }
        if(voiceEnabled == true){
          tracks[i].setEnabled(true);
          setMuteBtn(false);
        }
        }
     
      }
    
    }
    
     return (
       <>
     
    {newAllParticipants.length>0?(
    
      <>
       {muteBtn == false ?(<>
  
      <Row className="justify-content-md-center "    style={{display: "flex", justifyContent: "center"}}>
        <button style={{textAlign:'center',   width: "max-content", paddingLeft:"10px",paddingRight:"10px",  borderRadius:"10px",background:"#fff", border:"2px solid black"}} onClick={() => toogleRemoteVoice()}  >  Mute All</button>
        </Row>
  
    </>):( <>
  
  
  <Row className="justify-content-md-center "  style={{display: "flex", justifyContent: "center"}} >
        <button style={{textAlign:'center',   width: "max-content",   borderRadius:"10px",background:"#fff", paddingLeft:"10px",paddingRight:"10px", border:"2px solid black"}} onClick={() => toogleRemoteVoice()}  > 
           UnMute All
           </button>
        </Row>
  
  
    </> )}
      </>
    ):(
      null
    )}
    
       </>
     )
     }
  
  
  
  
  function VoiceFun({item}){
  
    const[muteBtn,setMuteBtn]=useState(false);
   
    var tracks = Array.from(item.tracks.values());
  
  
  const toogleRemoteVoice=()=>{
  
    for(let i=0; i<tracks.length; i++){
    
      let voiceEnabled = tracks[i].disabled;
      
  
    if(voiceEnabled == false){
      tracks[i].setEnabled(false);
      setMuteBtn(true);
    }
    if(voiceEnabled == true){
      tracks[i].setEnabled(true);
      setMuteBtn(false);
    }
    }
  
  }
  

  
  return (
    <>
     {muteBtn == false ?(
    <button   
    // style={{border:"2px solid red", textAlign:"center",display:"flex",width:"100%",borderRadius:"20px",background:"#fff", fontSize:"12px" }}
    style={{
      lineHeight: "1.3",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",   // Center horizontally
   // alignItems: "center",      // Center vertically
    width: "100%",
    borderRadius: "20px",
    background: "#fff",
    fontSize: "12px",
    }}
    onClick={() => toogleRemoteVoice()}>
        Mute
       <i class="mdi mdi-microphone text-gray-300 mr-2"></i>
       </button>
    ):( 
      <button  
    //   style={{   border:"2px solid red",textAlign:"center",width:"100%", display:"flex",borderRadius:"20px",background:"#fff", fontSize:"12px"}}   
    style={{
      lineHeight: "1.3",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",  // Center horizontally
      width: "100%",
  
      borderRadius: "20px",
      background: "#fff",
      fontSize: "12px",
      }}
       onClick={() => toogleRemoteVoice()}>
  UnMute
      <i class="mdi mdi-microphone-off text-gray-300 mr-2"></i>
      </button>
     )}
   
  
    </>
  )
  }



function ParticipantLivekit ({newBackCall,activeUser,projectType, MapParent}){



    



  const pauseVideo = ()=>{

    if(videoPaused == false){
      document.getElementById("streamingVideo").pause();
      videoPaused = true;
    }

  }

  const playVideo = ()=>{

    if(videoPaused == true){
      document.getElementById("streamingVideo").play();
      videoPaused = false;
    }

  }



  const callMapParent =()=>{
    MapParent();
      }

  const room =  useEnsureRoom();

activeRoom = room;

  const [isEmojiListVisible, setIsEmojiListVisible] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [showChatBox, setShowChatBox] = useState(true)
  const [selectedEmojis, setSelectedEmojis] = useState([]);
  const [microphoneMute, setMicrophoneMute] = useState(false);
  const [showChatBox_Mobile, setShowChatBox_Mobile] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isEmojiList_Mobile, setIsEmojiList_Mobile] = useState(false)
  const [textMessageShowHide , setTextMessageShowHide ] = useState(false)
  const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
const [showHelpModal,setShowHelpModal] = useState(false);

  const participants = useParticipants();
  const { send, chatMessages, isSending } = useChat();
  chatMessages.slice(0).reverse();
  const [showVoiceModal, setShowVoiceModal] = useState(false);
  const inputRef = useRef(null);
  const ulRef = useRef(null);
  const boxRef = useRef();
  const audioRef = useRef(null);



  
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 915);
    };
  
    handleResize();
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

   

  if (ulRef) {
    

    console.log(chatMessages);
    var element = document.querySelector('#scrollText');
    console.log(element);
    console.log(element.scrollHeight);
    mobScrollHeight = element.scrollHeight;
     element.scrollTop = element && element.scrollHeight+20;
   //   ulRef.current?.scrollTo({ bootom: ulRef.current.scrollHeight });
     
    }
  }, [ulRef, chatMessages]);



useEffect(()=>{

  console.log("INPUT")
  setIsEmojiListVisible(false);
  if(inputRef.current){

    console.log(inputRef.current.value);
    setIsEmojiListVisible(false);

  }
},[inputRef])

  async function handleSubmit(event) {
    event.preventDefault();
    if (inputRef.current && inputRef.current.value.trim() !== '') {
      setIsEmojiListVisible(false);
      setIsEmojiList_Mobile(false);
      if (send) {
        await send(inputRef.current.value);
        inputRef.current.value = '';
        if(isMobile.isMobile == true){
          inputRef.current.focus();
        }else{
          window.blur();
        }
     
   playVideo();
        setSelectedEmojis([])

    
       // var element2 = document.querySelector('#scrollText2');
//console.log(element2);

   //     element2.scrollTop = element2 && element2.scrollHeight+20;

        setIsEmojiListVisible(false);
      setIsEmojiList_Mobile(false);


      }
    }
  }

  const toogleVoiceModal =()=>{

    setShowVoiceModal(!showVoiceModal);
   }

   const toggleEmojiList = (event) => {
 
    setIsEmojiListVisible((prevState) => !prevState);
   // setShowChatBox(false)
    setButtonsDisabled((prevState2) => !prevState2); 
  
    
  };

  const handleEmojiSelect_NewCode = (emoji) => {
  
  setSelectedEmojis((prevSelectedEmojis) =>[ ]);

      inputRef.current.value = inputRef.current.value+ emoji.native
      
  };

  const handleShowChatMobile =async()=>{

      
    var element = document.querySelector('#scrollText');
    element.scrollTop = mobScrollHeight+120;

    setShowChatBox_Mobile(true);
    setIsEmojiListVisible(false)
  await delay(1000).then(()=>{
    element.scrollTop = mobScrollHeight+120;
  
  })


    }
    var chatLength

chatMessages.forEach((chat, index) => {

   chatLength =  chat.message.length

})
const isLongText = chatLength > 32;

    const SpaceInRight = isLongText ? "20px" : "45px";


    useEffect(()=>{

      navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((mediaStream) => {
       
  
   
      
      })

    },[navigator.mediaDevices])
    


    async function handleMicrophoneMute() {


      navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((mediaStream) => {

    
            mediaStream.getAudioTracks()[0].enable = true;
        setMicrophoneMute(mediaStream.getAudioTracks()[0].enabled);
       
      })
      await activeRoom.localParticipant.setMicrophoneEnabled(true);
    }
    
    async function handleMicrophoneUnMute() {

      navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((mediaStream) => {
      
   
        mediaStream.getAudioTracks()[0].enabled = false;
        setMicrophoneMute(mediaStream.getAudioTracks()[0].enabled);
       
      
      })
      await activeRoom.localParticipant.setMicrophoneEnabled(false);
    }

    
    const HideChatBox_Mobile = ()=>{


      setShowChatBox_Mobile(false)
      
      setIsEmojiList_Mobile(false)
      
      setTextMessageShowHide(false)
      if(videoPaused == true){
       
        document.getElementById("streamingVideo").play();
        videoPaused = false;
      }
    }

    const ShowChatBox_PC =async()=>{
  
        var element = document.querySelector('#scrollText');
        element.scrollTop = mobScrollHeight+120;

      setShowChatBox(!showChatBox)
      setIsEmojiListVisible(false)
      await delay(1000).then(()=>{
        element.scrollTop = mobScrollHeight+120;
      
      })
     }

     

     


     const UserConnectData = [];
for (let i=0; i< participants.length; i+= 3){
  UserConnectData.push(participants.slice(i, i + 3));
}



var numberOfIcon = window.innerWidth/40

Math.round(numberOfIcon)
const k =  Number.isInteger(numberOfIcon)

if(k == true){
 emojiNumber = numberOfIcon;
 
}
else{
 emojiNumber = Math.floor(numberOfIcon)+1;
}


useEffect(()=>{

  
    var numberOfIcon = window.innerWidth/40
   
     const k =  Number.isInteger(numberOfIcon)
    
     if(k == true){
      emojiNumber = numberOfIcon;
      
     }
    else{
      emojiNumber = numberOfIcon+1;
    }
  
  
  }, [emojiNumber])


  const screenSize = () => {
  

    var elem  = document.documentElement;       
  
    setIsFullScreen(!isFullScreen);

  
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitEnterFullScreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
  
  
       if (document.fullscreenElement !== null) {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) { /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE11 */
    document.msExitFullscreen();
  }
       }
  };
  const toggleMute = () => {
   var ele =  document.getElementById(arcwareAudioId);
    setIsMuted(!isMuted);
    webrtcA.toggleAudio(ele,!isMuted);
   
  };

  return(
    <>
    <div  className="d-block d-lg-none" >

{showChatBox_Mobile == false ?(<>
<div className=" Button_3_UI"  >
      
      <div >
          <button  
className="TopButton"   onClick={toggleMute}  >
          
          {isMuted ? 
            < HiSpeakerXMark style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />
: 
< BsFillVolumeUpFill   style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />

}
            
            
            </button>
   
      </div>
   
       <div>
           <button     className="MidButton"
           onClick={()=>toogleVoiceModal()}

           >
           
           <MdPeople style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }}/>

           </button>
       </div>
   
        <div  >
        <button  className="BottomButton"
   

         onClick={()=>screenSize()} 
        >

{isFullScreen ? 
          < IoMdContract style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }}/> 
        : 
           <GoScreenFull    style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }}/>
      }
          
          
          </button>
        </div>
   </div>



  
   <Row style={{position:"fixed",bottom:20, width:"100%",}}>
  
  {projectType == 'userGame'?(

<Col  xs={1}  sm={1}  md={1} lg={1}></Col>

  ):(
    <Col  xs={2}  sm={2}  md={2} lg={2}></Col>

  )}
  

 

  
  
          <Col  xs={2}  sm={2}  md={2} lg={2} 
          
          style={{
           // border:'2px solid red',
            
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            }}
            
            >
          <Button       style={{
  border: "none",
  backgroundColor:"#101010", 
  color: "#ffffff",
  opacity: "0.5",
borderRadius:"50%"
   
 }}        onClick={()=> handleShowChatMobile()}>
                 {/*   <BiConversation   style={{marginTop: "-2px"}}  />  */}

                 <IoMdChatbubbles 
                    style={{ fontSize: "20px",  marginTop: "-3px"}} 
            />
                    </Button>
          </Col>
  
  
          <Col   xs={2}  sm={2} md={2} lg={2} style={{  
         
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          
        //  border:'2px solid yellow',
      }}
          >


{microphoneMute == true?(<>   
          <Button          style={{
  border: "none",
  backgroundColor:"#101010", 
  color: "#ffffff",
  opacity: "0.5",
  borderRadius:"50%"
   
 }}   onClick={()=>handleMicrophoneUnMute()}>
            


                   <FaMicrophone    style={{ marginTop: "-3px"}}  />

                            </Button>      
                       </>):(
                        <>

<Button       style={{
  
  border: "none",
  backgroundColor:"#101010", 
  color: "#ffffff",
  opacity: "0.5",
  borderRadius:"50%"

   
 }}    onClick={()=>handleMicrophoneMute()}>


 <FaMicrophoneSlash   style={{ color:"white",marginTop: "-3px"}}   /> 




                 </Button>      
                        </>
                       ) }    
                       
                       
                  
          </Col>
  
  

          <Col style= {{ display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',}} xs={2}  sm={2} md={2} lg={2}  
  // style={{ border:'2px solid red',   }}
  >
          
          <div>

<Button 
 
style={{
  border: "none",
  backgroundColor:"#101010", 
  color: "#ffffff",
  opacity: "0.5",
  borderRadius:"50%"
}}  
onClick={()=>callMapParent()} 
   >

< BiSolidMap   style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />

</Button>



</div>
      
        
        </Col>



        {projectType == 'userGame'?(

        <Col style= {{ display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',}} xs={2}  sm={2} md={2} lg={2}  
  // style={{ border:'2px solid red',   }}
  >
          
          <div>

<Button 
 
style={{
  border: "none",
  backgroundColor:"#101010", 
  color: "#ffffff",
  opacity: "0.5",
  borderRadius:"50%"
}}  
onClick={()=>newBackCall()} 
   >

< FaArrowRightToCity  style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />

</Button>



</div>
      
        
        </Col>):(null)}

        <Col  xs={2}  sm={2}  md={2} lg={2} 
          
          style={{
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            }}
            
            >
          <Button       style={{
  border: "none",
  backgroundColor:"#101010", 
  color: "#ffffff",
  opacity: "0.5",
borderRadius:"50%"
   
 }}        onClick={()=> setShowHelpModal(true)}>
                 

                 <BiHelpCircle 
                    style={{ fontSize: "20px",  marginTop: "-3px"}} 
            />
                    </Button>
          </Col>

        {projectType == 'userGame'?(
    <Col  xs={1} sm={1}   md={1} lg={1}
    >
    </Col>

        ):(
          <Col  xs={2} sm={2}   md={2} lg={2}
        
          >
          </Col>
        )}

      
  
  
  </Row>
  
</>):(null)}






  
  {showChatBox_Mobile == true ?(<>
  
     <div style={{position:"fixed", height:"100vh", width:"100vw", top:0,botoom:0,left:0,right:0 ,zIndex:"100",background:"black",opacity:0.8}}>

       <div >
        <button  className='backButton'  onClick={()=>HideChatBox_Mobile()}  ><FaAngleLeft   style={{  color: "white", fontSize: "20px"}}   /></button>
       </div>








<div className="chat-app" id="scrollText">

<div className="chat-messages"  >



{chatMessages.map((chat,index) => (
  <div key={index}    >
    {chat.from.identity !== partName ?(
      <>


<div  style={{width:'100%',float: "right"}}>

<div     style={{  width:"fit-content" , color:"#ffffff",paddingTop:"10px",float: "right",
  }}>

<div   style={{ 
fontSize: "10px",paddingLeft:"10px",
  color:"white"}}> 
{chat.from.identity}
</div> 


<div 

style={{
width:"max-content", 
float: "right",
maxWidth: "80vw",
backgroundColor:"white",
wordWrap: "break-word" ,  
borderRadius:"16px ",
lineHeight: "1.2",
}}


>

    <div  
  
       style={{   
      paddingRight:SpaceInRight, 
      maxWidth: "80vw",
           paddingLeft:"10px",     
            wordWrap: "break-word" ,  
           // paddingTop:"3px",
           paddingTop:"5px",
            fontSize: "12px",
            color:"black",   
          // minWidth:"5vw",
           }}   
       >
         {chat.message}
      </div>
       
       
       <div 
        style={{
           textAlign:"right",
       fontSize: "6px",
       paddingRight:"10px",
       width:"100%",
        marginTop:"-2px"
      }} 
      
      >
           <span style={{ color:"black",  }}>
            {new Date(chat.timestamp).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})} 
            </span>
      </div>
</div>
</div>


</div>



 </>
    ):(
<>

<div style={{float:'left' ,width:"100%", 
                        color:"#ffffff", paddingTop:"10px", 
      }}
      >
          <div  style={{ display: "flex", paddingLeft:"10px",  fontSize: "10px"}}  >
             You
          </div>

    <div   style={{ 
                  width:"max-content", 
              //    border:"2px solid black",
             maxWidth: "80vw",
             background:"#007AFF", 
             wordWrap: "break-word" ,  
             borderRadius:"16px ",
             lineHeight: "1.2",
             }}>

            <div  style={{   
          
        paddingRight:SpaceInRight, 
             paddingLeft:"10px",     
              wordWrap: "break-word" ,  
              paddingTop:"5px",
          //    minWidth:"5vw",
              fontSize: "12px",
              color: "#ffffff"   
                    
             }}>  {chat.message} </div>  





   <div
style={{
  // border:"1px solid blue",
 textAlign: "end",
 fontSize: "6px",
 paddingRight:"12px",
 color:"#ffffff",
  marginTop:"-2px"
}} 

>
{new Date(chat.timestamp).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})} 
</div>   




</div>


</div>





   </> )}


</div>
 ))}

</div>

</div>






   <div style={{ display: 'flex', width: '100%',
   position: "fixed",
    paddingBottom:"12px",
    paddingRight:"8px",
    paddingLeft:"8px",
         bottom: "0px",
        
        
         }}>



<form  onSubmit={handleSubmit}  style={{width:"100%",left:"0px",}} >

    
<InputGroup   id="chatMessage">
        <Form.Control
          placeholder="Type a message here . . "
          disabled={isSending}
          ref={inputRef}
        

          style={{
            
            color:"white", border:"none",
            backgroundColor: "#d9d9d954",
            borderRadius:"20px 0px 0px 20px",
           borderColor:"transparent",
           outline: "none",
          }} />
        
        <Button type="submit" style={{ //backgroundColor:"#101010",opacity:"0.5",
 color:"white", border:"none",
 backgroundColor: "#d9d9d954",

borderRadius:"0px 20px 20px 0px", outline: "none", }} 
         > 
                <BsSend   style={{ color: "#A8A8A8", fontSize: "23px", border:'none', borderColor:"transparent"  }} /> 
        </Button>
     

</InputGroup>
 

</form>



   </div>


     </div>
  
  </>):(null)}

   
  </div>











{/*================= Laptop ui input box   ================================== */}


<Row  className="container d-none d-lg-flex"  style={{marginLeft: "16%"}}>


<Col xs ={10}  style={{

  padding:"0px"}}   >



<div className='message-content'  id="scrollText" style={{paddingRight:"2px",width:"300px"}} >


{showChatBox == true ?(<>



<div ref={ulRef}>

{chatMessages.map((chat,index) => (
  <div key={index}    >
    {chat.from.identity !== partName ?(
      <>


<div  style={{width:'80%',float: "right",}}>

<div     style={{  width:"fit-content" , color:"#ffffff",
paddingTop:"10px",
float: "right", 
  }}>

<div   style={{ 
fontSize: "10px",paddingLeft:"10px",
  color:"white"}}> 
{chat.from.identity}
</div> 


<div 

style={{
    
float: "right",
//  maxWidth:"20vw",
maxWidth:"20vw",
width:"fit-content",
backgroundColor:"white",
wordWrap: "break-word" ,  
borderRadius:"16px ",
//lineHeight: "1.2",
}}


>

    <div  
  
       style={{   
        
      paddingRight:SpaceInRight, 
           paddingLeft:"10px",     
            wordWrap: "break-word" ,  
          paddingTop:"3px",
            fontSize: "12px",
            color:"black",   
            
          // minWidth:"5vw",
           }}   
       >
         {chat.message}
      </div>
       
       
       <div 
        style={{
           textAlign:"right",
       fontSize: "6px",
       paddingRight:"10px",
       width:"100%",
        marginTop:"-3px"
      }} 
      
      >
           <span style={{ color:"black",  }}>
           {new Date(chat.timestamp).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})} 
            </span>
      </div>
</div>
</div>


</div>



 </>
    ):(
<>

<div style={{float:'left' ,width:"100%", 
                        color:"#ffffff", paddingTop:"10px", 
      }}
      >
          <div  style={{ display: "flex", paddingLeft:"10px",  fontSize: "10px"}}  >
             You
          </div>

    <div   style={{ 
                maxWidth:"80%",
                width:"fit-content",
             background:"#007AFF", 
             wordWrap: "break-word" ,  
             borderRadius:"16px ",
           //  lineHeight: "1.3",
           
             }}>

            <div  style={{   
          
        paddingRight:SpaceInRight, 
             paddingLeft:"10px",     
              wordWrap: "break-word" ,  
              paddingTop:"3px",
          //    minWidth:"5vw",
              fontSize: "12px",
              color: "#ffffff"   
                    
             }}>  {chat.message} </div>  





   <div
style={{
  // border:"1px solid blue",
 textAlign: "end",
 fontSize: "6px",
 paddingRight:"12px",
 color:"#ffffff",
  marginTop:"-3px"
}} 

>
{new Date(chat.timestamp).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})} 
   </div>   




</div>


</div>





   </> )}


</div>
 ))}

</div>



</>):(<>


</>)}

</div>








{isEmojiListVisible && (
  <>
       <div className='chatUi'    ref={boxRef}    >
          
            <Picker data={data} 
             // onEmojiSelect={console.log}
             onEmojiSelect={e => handleEmojiSelect_NewCode(e)} 
             
             emojiSize={20}
              // emojiButtonSize={22}
             //  maxFrequentRows={5}
                 perLine={7}

            />

      </div>
        </> )}









<form  onSubmit={handleSubmit}   >

    
<InputGroup id="chatMessage"  style={{ right:"0px"}} >
        <Form.Control
          placeholder="Type a message here . . "
          disabled={isSending}
          ref={inputRef}
         onChange={()=> setIsEmojiListVisible(false)}
          style={{
          //  background:"#36454F",opacity:"0.5",
            color:"white", border:"none", 

            backgroundColor:"black",opacity:"0.6",

          borderRadius:"20px 0px 0px 20px", outline: "none",
         // border:"2px solid #36454F	", padding:"2%",color:"#fff"
          }}
          

          />
        
 
        <InputGroup.Text  style={{ //backgroundColor:"#101010",opacity:"0.5",
 backgroundColor:"black",opacity:"0.6",
  outline: "none", border:"none",   boxShadow: "none", }} 
          onClick={toggleEmojiList}> 
                <BsFillEmojiSmileFill   style={{ color: "#A8A8A8", fontSize: "23px", border:'none', borderColor:"transparent"  }} /> 
        </InputGroup.Text>
     


        <InputGroup.Text  style={{  borderRadius:"0px 20px 20px 0px", 
     //   backgroundColor:"#101010",opacity:"0.5",
     backgroundColor:"black",opacity:"0.6",
         border:"none"}}  
         onClick={ShowChatBox_PC }
        >
          <IoMdChatbubbles 
            style={{ color: "#A8A8A8", fontSize: "26px", }} 
            />
           </InputGroup.Text>
     

</InputGroup>
 

</form>


</Col>

<Col xs ={2}  
 style={{
 // border:"2px solid blue" ,
 display:"flex"}}>

        <div  style={{display:"flex" ,width:"100%", 
      //   border:"2px solid blue", 
        }}> 

            <div    style={{
          //  border:"2px solid green",
              paddingRight:"12px", 
              paddingLeft:"24px"
            }} >

   


{microphoneMute == true?(<>
<button style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  border: "none",
  backgroundColor: "#101010",
  color: "#ffffff",
  opacity: "0.5",
  height:"37px",
  width:"37px"
 
  
}} onClick={()=>handleMicrophoneUnMute()}> < IoIosMic     style={{ color: "#ffffff", fontSize: "20px" }}  /></button>

</>):(<>

<button style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  border: "none",
  backgroundColor: "#101010",
  color: "#ffffff",
  opacity: "0.5",
  height:"37px",
  width:"37px"

  
}} onClick={()=>handleMicrophoneMute()}> <IoIosMicOff  style={{ color: "#ffffff", fontSize: "20px" }}   /></button>

</>)}






            </div>



            <div    
          style={{
            //  border:"2px solid green",
                paddingRight:"12px", 
                paddingLeft:"24px"
              }} 
            >
     
      
      
<button style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  border: "none",
  backgroundColor: "#101010",
  color: "#ffffff",
  opacity: "0.5",
  height:"37px",
  width:"37px"
 
}}


onClick={()=>toogleVoiceModal()}
>
  
 {/*  <FaUsers
        style={{ color: "#ffffff", 
        fontSize: "20px",
     
       }}
      />
      */}

     < MdPeople  style={{ color: "#ffffff", 
        fontSize: "20px", }} />



</button>
 
      
      
            </div>  



<div style={{
          //  border:"2px solid green",
              paddingRight:"12px", 
              paddingLeft:"24px"
            }} >

<button 

style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  border: "none",
  backgroundColor: "#101010",
  color: "#ffffff",
  opacity: "0.5",
  height:"37px",
  width:"37px"
        

}}
onClick={()=>callMapParent()} 
   >

< BiSolidMap   style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />

</button>



</div>

        </div>



</Col>


</Row>


<Modal
      show={showHelpModal}
      onHide={() =>setShowHelpModal(false)}
      onOk={() => setShowHelpModal(false)}
      onCancel={() => setShowHelpModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
    >
      
      <Modal.Body   style={{border:'none',borderRadius:"20px",opacity:'0.95'}}>
      <HelpUI/>
     </Modal.Body> </Modal>



<Modal
      show={showVoiceModal}
      onHide={() =>setShowVoiceModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
    >
      
      <Modal.Body   style={{border:'none',borderRadius:"20px",opacity:'0.95'}}>
      < Card style={{border:'none',background:'transparent'}}>
     
     <Row className="justify-content-md-center ">
      <h5 style={{textAlign:'center'}}>Audio Groups</h5>
      </Row>


     <Row >

      <p style={{textAlign:"center"}}>Audio group allows you to selectively mute participants in the same space, enabling you to hear only the users you want to listen to. When you mute
        someone in the audio group, it silences their audio from your end, but they can still hear your voice. </p>
     </Row>


<Row>

</Row>

<div  style={{ height: '200px', overflowY: 'auto' }}>
 {UserConnectData.map((chunk, rowIndex) => (
     <Row key={rowIndex}    >
       {chunk.map((item, columnIndex) => 
        (
          <Col  key={columnIndex}   md={12} lg={4} xl={4} style={{paddingBottom:"5px", }}>


              <Row   >
        
               <Col   md={10}  lg={10} xl={10}   >
 
                  <Row >

          

                      <Col xs={2}  sm={2} md={2} lg={2} xl={2} >
                     {item.avatarimageUrl == undefined?(
                           <img src="https://cdn-icons-png.flaticon.com/256/147/147142.png" className="rounded-circle shadow-4"
                           style={{ width: "24px",}}  alt="Avatar" />
                     ):(
     <img src={item.avatarimageUrl} className="rounded-circle shadow-4"
     style={{ width: "24px",}}  alt="Avatar" />
                     )}
                   
                      </Col>

                      <Col xs={6} sm={6} md={6} lg={6} xl={6}  style={{textAlign:"center",}}>
                          <p style={{// fontSize:"15px"
                           fontSize:"13px"
                          }}>{item.identity.split("&")[0]}</p>

                      </Col>
                 
                      <Col xs={4} sm={4} md={4} lg={4} xl={4}     
                         style={{paddingLeft:"5px", paddingRight:"5px", 
                         }}>

                            <VoiceFun  item={item}  /> 

                          

                      </Col>
                  </Row>

                 </Col>

               </Row>


          </Col>
          ))}
        </Row>
      ))}
</div>






<br/><br/>
         <Row className="justify-content-md-center " >
             <VoiceMuteUnMuteAll participants={participants} />
      </Row>

</Card>
      </Modal.Body>
    
    </Modal>


</>
    
   )
}



export default function GamePlay({homeParentCallback,backToGame,UiMessage,projectType,projectId,sendMessageCall,closeGame,currentUser,plotId}) {



  const [arcwareApplication, setArcwareApplication] = useState(null);
  const [applicationResponse, setApplicationResponse] = useState("");

  const [videoStart, setVideoStart] = useState(false);
  const[roomJoined, setRoomJoined] = useState(false);
const [showPopProject,setShowPopProject] = useState(false);
const[showHelpModal,setShowHelpModal] = useState(false);
const [showMapUi,setShowMapUi] = useState(false);

const[initialMessageSendDone, setInitialMessageSendDone] = useState(true);
const [isMuted, setIsMuted] = useState(false);
const [isFullScreen, setIsFullScreen] = useState(false);
const sizeContainerRef = useRef(null);
const videoContainerRef = useRef(null);
const videoRef = useRef(null);
const audioRef = useRef(null);
const [livekitToken,setLiveKitToken] = useState();
const [showMapMessage,setShowMapMessage] = useState(false);

const [gameStyle,setGameStyle]=useState({display:'none'});



useEffect(() => {
  let formElement;
  const handleClick = event => {


    if(event.target.className == "form-control"){
 
      formElement = event.target;



      if(videoPaused == false){
        console.log(webrtcA);
        console.log(arcwareApplication);
        document.getElementById("streamingVideo").pause();
        videoPaused = true;
      }
    }

    if(event.target.className == "streaming-video"){

      if(videoPaused == true){
        event.preventDefault();
        formElement.blur();
        document.getElementById("streamingVideo").play();
        videoPaused = false;
      }
    }

  };



window.addEventListener('click', handleClick);


}, []);


    const delay = ms => new Promise(
      resolve => setTimeout(resolve, ms)
    );

    const responseCallback = async(message)=>{

      console.log(message);

if(message == "closeUi" && projectType == "infinityGame"){
  homeParentCallback(message);
showMapButton = true;
setVideoStart(false);
}

if(message == "closeUi" && projectType == "userGame"){
  homeParentCallback(message);
showMapButton =true;
 setVideoStart(false);
  }

if(message.includes('https') == true){
  homeParentCallback(message);
}
 
if(message == "openCity"){
  backToGame();
}

    if(message.includes('projectId') == false &&  message !== "openCity" && message.length == 24 && message.includes('https') == false && message !== "closeUi" ){

      //var url2 = message.split("&");
      var plotIdNew = message;

      console.log(plotIdNew);
    
      const unitData =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterinventory/${plotIdNew}`)
      console.log(unitData.data);

      if(unitData.data.inventorySetting.entryType == "Public"){

        console.log("Public");


        var projectIdNew = unitData.data.deployedScene && unitData.data.deployedScene.projectId;
  
  console.log(projectIdNew);
  
        if(projectIdNew == undefined){
  
          setShowPopProject(true);
          showMapButton=true;
  
        }
        
        if(projectIdNew !== undefined){

          var newMessage = "userId="+currentUser.id+"&"+"newdata="+"&"+"projectId="+projectIdNew+"&"+"sceneId="+unitData.data.deployedScene.id+"&"+"plotId="+unitData.data._id+"&"+"unrealocation="+unitData.data.unreaLocation+"&"+"unreaRotation="+unitData.data.unreaRotation+"&"+"networkIp="+unitData.data.deployedScene.networkIp;


          console.log("BACK CALL");
  
        setInitialMessageSendDone(true);
  
        var url = 'https://mint.infinityvoid.io/gameApi/livekitToken/disconnectUsers';
        const formData = { roomName: activeRoom.name, userId:activeRoom.localParticipant.identity};
  
        try {
          const response = await axios.post(url, formData);
  
      
          // Work with the response...
      } catch (err) {
          if (err.response) {
              // The client was given an error response (5xx, 4xx)
          } else if (err.request) {
              // The client never received a response, and the request was never left
          } else {
              // Anything else
          }
      }
  
      setLiveKitToken(undefined);
     
      webrtcA.disconnect();
      setGameStyle({ display:"none" });
     // webrtcA.webSocketController.close();      
      setRoomJoined(false);
  showMapButton=false;
      
  await delay(1000).then(()=>{
    homeParentCallback(newMessage);
  })
  
      }


}else{

  var allowedAddressOrig = unitData.data.inventorySetting.allowedAddress;
  
  const allowedAddress = allowedAddressOrig.map(element => {
    return element.toLowerCase();
  });

  console.log(allowedAddress);

  if(allowedAddress && allowedAddress.includes(currentUser.walletAddress)){

  
    var projectIdNew = unitData.data.deployedScene && unitData.data.deployedScene.projectId;

    console.log(projectIdNew);

    if(projectIdNew == undefined){

      setShowPopProject(true);
      showMapButton=true;

    }
    
    if(projectIdNew !== undefined){

      var newMessage = "userId="+currentUser.id+"&"+"newdata="+"&"+"projectId="+projectIdNew+"&"+"sceneId="+unitData.data.deployedScene.id+"&"+"plotId="+unitData.data._id+"&"+"unrealocation="+unitData.data.unreaLocation+"&"+"unreaRotation="+unitData.data.unreaRotation+"&"+"networkIp="+unitData.data.deployedScene.networkIp;



    setInitialMessageSendDone(true);

    var url = 'https://mint.infinityvoid.io/gameApi/livekitToken/disconnectUsers';
    const formData = { roomName: activeRoom.name, userId:activeRoom.localParticipant.identity};

    try {
      const response = await axios.post(url, formData);

  
      // Work with the response...
  } catch (err) {
      if (err.response) {
          // The client was given an error response (5xx, 4xx)
      } else if (err.request) {
          // The client never received a response, and the request was never left
      } else {
          // Anything else
      }
  }

  setLiveKitToken(undefined);

  webrtcA.disconnect();
  setGameStyle({ display:"none" });
 // webrtcA.webSocketController.close();
setRoomJoined(false);
showMapButton=false;
  
await delay(1000).then(()=>{
homeParentCallback(newMessage);
})

  }

}else{alert('NOT ALLOWED')}
 
    }
  }


  if(message.includes('projectId') == true){

    var url2 = message.split("&");
    var plotIdNew = url2[4].split("=")[1];

    console.log(plotIdNew);
  
    const unitData =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterinventory/${plotIdNew}`)
    console.log(unitData.data);

    if(unitData.data.inventorySetting.entryType == "Public"){

      console.log("Public");


      var projectIdNew = url2[2].split("=")[1];



      if(projectIdNew == ""){

        setShowPopProject(true);
        showMapButton=true;

      }
      
      if(projectIdNew !== ""){

      setInitialMessageSendDone(true);

      var url = 'https://mint.infinityvoid.io/gameApi/livekitToken/disconnectUsers';
      const formData = { roomName: activeRoom.name, userId:activeRoom.localParticipant.identity};

      try {
        const response = await axios.post(url, formData);

    
        // Work with the response...
    } catch (err) {
        if (err.response) {
            // The client was given an error response (5xx, 4xx)
        } else if (err.request) {
            // The client never received a response, and the request was never left
        } else {
            // Anything else
        }
    }

    setLiveKitToken(undefined);
   
    webrtcA.disconnect();
    setGameStyle({ display:"none" });
   // webrtcA.webSocketController.close();      
    setRoomJoined(false);
showMapButton=false;
    
await delay(1000).then(()=>{
  homeParentCallback(message);
})

    }


}else{

var allowedAddressOrig = unitData.data.inventorySetting.allowedAddress;

const allowedAddress = allowedAddressOrig.map(element => {
  return element.toLowerCase();
});

console.log(allowedAddress);

if(allowedAddress && allowedAddress.includes(currentUser.walletAddress)){


  var projectIdNew = url2[2].split("=")[1];



  if(projectIdNew == ""){

    setShowPopProject(true);
    showMapButton=true;

  }
  
  if(projectIdNew !== ""){

  setInitialMessageSendDone(true);

  var url = 'https://mint.infinityvoid.io/gameApi/livekitToken/disconnectUsers';
  const formData = { roomName: activeRoom.name, userId:activeRoom.localParticipant.identity};

  try {
    const response = await axios.post(url, formData);


    // Work with the response...
} catch (err) {
    if (err.response) {
        // The client was given an error response (5xx, 4xx)
    } else if (err.request) {
        // The client never received a response, and the request was never left
    } else {
        // Anything else
    }
}

setLiveKitToken(undefined);

webrtcA.disconnect();
setGameStyle({ display:"none" });
// webrtcA.webSocketController.close();
setRoomJoined(false);
showMapButton=false;

await delay(1000).then(()=>{
homeParentCallback(message);
})

}

}else{alert('NOT ALLOWED')}

  }
}

}

    
  const videoInitialized = async() => {
   
setInitialMessageSendDone(false);
setVideoStart(true);
}

  const sendSocketMessage = async(messageSoc) => {


console.log(messageSoc);

    
       }

       useEffect(() => {
        const startArcware=async()=>{
        if(initialMessageSendDone == true){

          console.log(videoContainerRef.current.children[0]);


          if(webrtcA !== undefined){

            console.log("CLOSE INS");
            webrtcA.disconnect();
            var elem  = document.getElementById('disconnectOverlay');
            console.log(elem);
            console.log(videoContainerRef.current.children[0]);

            videoContainerRef.current.removeChild(videoContainerRef.current.children[0]);
   

           // webrtcA.webSocketController.close();
          }

          
        const { Config, PixelStreaming, Application } = ArcwareInit(
            {
              shareId: projectId
            },
            {
              initialSettings: {
                StartVideoMuted: false,
                AutoConnect: true,
                AutoPlayVideo: true,
                UseMic:true,
                GamepadInput: false,
                TouchInput: true,
                XRControllerInput:false
                
              },
              settings: {
                infoButton: false,
                stopButton:false,
                micButton: false,
                audioButton: false,
                fullscreenButton: false,
                settingsButton: false,
                connectionStrengthIcon: true,
              },
            }
          );
          
            console.log("TESTAPP")
          PixelStreaming.videoInitializedHandler.add(() => videoInitialized());
           

          PixelStreaming.websocketOnCloseHandler.add((event) => {
            console.log(`WebSocket closed. CloseCode: ${event.code} Reason: ${event.reason}`);
        });
        setArcwareApplication(Application);

       
        Application.getApplicationResponse((response) =>

       
        responseCallback(response)
        );

        PixelStreaming.toggleMic(true,true);
     
       console.log(videoContainerRef.current);
        // Append the application's root element to the video container ref
        if (videoContainerRef?.current) {
          console.log("appendChild");
          videoContainerRef.current.appendChild(Application.rootElement);
        }
        setInitialMessageSendDone(false);
        shareIdError = false;
        webrtcA = PixelStreaming;

        
            await delay(300000).then(()=>{
        
              console.log(shareIdError);
        
              if(videoStart == false && shareIdError == false){
                homeParentCallback("VIDEO NOT START");
              
              }
              })
      }
    }
      startArcware();

      }, [projectId]);

useEffect(()=>{

  const messageSend = async()=>{

  
      let consoleDescriptor = {
         MessageId:UiMessage
       };




      if(UiMessage !== undefined && initialMessageSendDone == false && videoStart == true){

  
        setGameStyle({ width: "100vw", height: "100vh"  });
        var newUiMessage = JSON.parse(UiMessage);




 if(projectType == "infinityGame" && newUiMessage.projectType == "infinityGame"){
 

  if(currentUser.walletAddress == "Guest"){
    partName = currentUser.name;
   }
   if(currentUser.linkedENSName.linkedENSDone !== undefined){
   partName = currentUser.linkedENSName.name
   }
   if(currentUser.linkedENSName.linkedENSDone == undefined &&currentUser.walletAddress !== "Guest"){
    partName = currentUser.name+"#"+currentUser.walletAddress.slice(38);
    }


  setInitialMessageSendDone(true); 
  shareIdError = true;    

  console.log(shareIdError);
  await delay(1000).then(()=>{
    console.log(consoleDescriptor);
    arcwareApplication.emitUIInteraction(consoleDescriptor);
    var elem  = document.getElementById('disconnectOverlay');
    console.log(elem);
    elem.style.display = 'none'; 
    elem.style.zIndex = "-1";
  

var newUserName = partName;
    tokenFetcher(newUserName);
  })

 
 }

 if(projectType == "userGame" && newUiMessage.projectType == "userGame"){

  setShowMapUi(false);
  setInitialMessageSendDone(true);
  shareIdError = true;       

  if(currentUser.walletAddress == "Guest"){
    partName = currentUser.name;
   }
   if(currentUser.linkedENSName.linkedENSDone !== undefined){
   partName = currentUser.linkedENSName.name
   }
   if(currentUser.linkedENSName.linkedENSDone == undefined &&currentUser.walletAddress !== "Guest"){
    partName = currentUser.name+"#"+currentUser.walletAddress.slice(38);
    }

  await delay(1000).then(()=>{
    console.log(consoleDescriptor);
    arcwareApplication.emitUIInteraction(consoleDescriptor);
    
    var elem  = document.getElementById('disconnectOverlay');
    console.log(elem);
    elem.style.display = 'none'; 
    elem.style.zIndex = "-1";
   

    tokenFetcherUserGame(newUiMessage);
  })
 }
          
       
     
       
  
        }
  
  
  }

messageSend();

},[sendMessageCall,arcwareApplication,projectId,videoStart])

useEffect(()=>{


const closeRoom =async()=>{

  setInitialMessageSendDone(true);

  if(closeGame == true ){
   
    setInitialMessageSendDone(true);
    showMapButton=true;
    if(roomJoined == true && activeRoom.name !== undefined){

      var url = 'https://mint.infinityvoid.io/gameApi/livekitToken/disconnectUsers';
      const formData = { roomName: activeRoom.name, userId:activeRoom.localParticipant.identity};


    try {
      const response = await axios.post(url, formData);

  
      // Work with the response...
  } catch (err) {
      if (err.response) {
          // The client was given an error response (5xx, 4xx)
      } else if (err.request) {
          // The client never received a response, and the request was never left
      } else {
          // Anything else
      }
  }
      }
      
   
    setRoomJoined(false);
    setLiveKitToken(undefined);
   
    webrtcA.disconnect();
    setGameStyle({ display:"none" });
   // webrtcA.webSocketController.close();

        
  }
}
  closeRoom();

},[closeGame])


const tokenFetcher = async (newUserName) => {



   let maxPart = "100";

   console.log(newUserName);

   const resData = await axios.get('https://mint.infinityvoid.io/gameApi/livekitToken', {
    params: {
      roomName: projectId,
      name:newUserName+"&"+currentUser.id,
      maxParticipants:maxPart
    }
  });



  //const res = await fetch(`https://mint.infinityvoid.io/gameApi/livekitToken?roomName=${projectId}&name=${newUserName}&maxParticipants=${maxPart}`);
  //const resData = await res.json();

  console.log(resData.data);

  setLiveKitToken(resData.data.accessToken);

await delay(7000).then(()=>{


if(videoStart == true){
  homeParentCallback("closeUi");
  showMapButton = true;
  setVideoStart(false);
}
})


}

const tokenFetcherUserGame = async (newUiMessage) => {
 

  
     const unitData =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterinventory/${plotId}`)
     await delay(2000).then(async()=>{

      const resData = await axios.get('https://mint.infinityvoid.io/gameApi/livekitToken', {
        params: {
          roomName: newUiMessage.sceneId,
          name:partName+"&"+currentUser.id+"&"+plotId,
          maxParticipants:unitData.data.userLimit
        }
      });
   // const res = await fetch(`https://mint.infinityvoid.io/gameApi/livekitToken?roomName=${newUiMessage.sceneId}&name=${partName}&maxParticipants=${unitData.data.userLimit}`);
   // const resData = await res.json();
  
 //   await Room.connect('wss://metaverseinfinityvoidio-4om2t9s4.livekit.cloud', resData.accessToken);

   setLiveKitToken(resData.data.accessToken)

 
   });


   await delay(7000).then(()=>{
    if(videoStart == true){
      homeParentCallback("closeUi");
      showMapButton = true;
      setVideoStart(false);
    }
    })

  }






  const sendLocaion =async(data)=>{
    setShowMapUi(false);

    if(projectType == "userGame" && data !== "closeMap"){


      if(data.unreaLocation !== undefined){

        console.log("NewMapCall");

        setInitialMessageSendDone(true);
  
        var url = 'https://mint.infinityvoid.io/gameApi/livekitToken/disconnectUsers';
        const formData = { roomName: activeRoom.name, userId:activeRoom.localParticipant.identity};
  
        try {
          const response = await axios.post(url, formData);
  
      
          // Work with the response...
      } catch (err) {
          if (err.response) {
              // The client was given an error response (5xx, 4xx)
          } else if (err.request) {
              // The client never received a response, and the request was never left
          } else {
              // Anything else
          }
      }
  
      setLiveKitToken(undefined);
     
      webrtcA.disconnect();
      setGameStyle({ display:"none" });
 // webrtcA.webSocketController.close();
  setRoomJoined(false);
  showMapButton=true;
      
  await delay(1000).then(()=>{
    homeParentCallback(data);
  })

      }else{
        responseCallback(data);
      }


}

    if(data !== "closeMap" && projectType == "infinityGame"){

   if(data.includes == undefined){

        mapMessage = "Teleporting to Parcel "+ data.name ;

        setShowMapMessage(true);
  
        await delay(1000).then(()=>{
          arcwareApplication.emitUIInteraction(data);
        
        }).then(async()=>{
          await delay(3000).then(()=>{
            setShowMapMessage(false);
          
          })
        })
      }else{

     
        responseCallback(data);
      }

    }
  }



  const screenSize = () => {
  

    var elem  = document.documentElement;       
  
    setIsFullScreen(!isFullScreen);
  
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitEnterFullScreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
  
  
       if (document.fullscreenElement !== null) {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) { /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE11 */
    document.msExitFullscreen();
  }
       }
  };

  const toggleMute = () => {
    webrtcA.toggleAudio(videoRef,!isMuted);

    setIsMuted(!isMuted);
  

  
  };



async function onConnected(room) {

  // await room.localParticipant.setCameraEnabled(false);
  // await room.localParticipant.setMicrophoneEnabled(false);
}

const mapWork = ()=>{
  setShowMapUi(true);
}

useEffect(()=>{

  console.log("CALLING")

const initialData = async()=>{
  var units =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterInventory/`);

  var unitsFiltered = units.data.rows.filter(e => e.deployedScene && e.deployedScene.id !== null);

  
  for(let i=0; i<unitsFiltered.length; i++){
      var data = unitsFiltered[i];
      var activeScene =  await axios.get(`https://mint.infinityvoid.io/gameApi/builderscene/${unitsFiltered[i].deployedScene.id}`);
      Object.assign(data, {sceneDataNew:activeScene.data});

     
      newUnitSceneData.push(data);
  }

console.log(newUnitSceneData);
 
}
if(newUnitSceneData.length<1){
  initialData();
}

},[newUnitSceneData])


const newBackCall=()=>{
backToGame();
}

  return (
    
    <>




    {showMapUi?(<>
        <DiscoverScreen userId={currentUser.id} ParentCallback={sendLocaion} newUnitSceneData={newUnitSceneData}/>
        </>):(null)}


        {showMapMessage?(<>
        <LoadingScreen loadingMessage={mapMessage}/>
        </>):(null)}

        
        <Modal
        
      show={showHelpModal}
      onHide={() =>setShowHelpModal(false)}
      onOk={() => setShowHelpModal(false)}
      onCancel={() => setShowHelpModal(false)}
      centered
      size="xl"
      
    >
       <Modal.Header closeButton/>
      <Modal.Body   style={{border:'none',borderRadius:"20px",opacity:'0.95'}}>
      <HelpUI/>
     </Modal.Body> 
     </Modal>




        <Modal
      show={showPopProject}
      onHide={() =>setShowPopProject(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header closeButton/>
 

      <Modal.Body   style={{border:'none',borderRadius:"20px",opacity:'0.95'}}>
      <Card style={{border:'none',background:'transparent'}}>
<h3>

Coming Soon

</h3>
     </Card>
     </Modal.Body> 
     </Modal>


       

 
 
 {livekitToken !== undefined?(
 
   <Row  style={{position:"fixed",bottom:15, width:"100%",zIndex:"40"}}>
 
 
 <Col xs={4}   >
 <LiveKitRoom
 token={livekitToken}
 serverUrl="wss://metaverseinfinityvoidio-4om2t9s4.livekit.cloud"
 connect={true}
 onConnected={room => onConnected(room)}
 video={false}
 audio={false}
 
 > 
 <ParticipantLivekit newBackCall={newBackCall} activeUser={currentUser} MapParent={mapWork} showMapButton={showMapButton} projectType={projectType} />
 
 
   <AudioConference style={{display:"none"}}/>
 
 
 
 </LiveKitRoom>
 
 </Col>
 
 <Col xs={3} >
 
 </Col>
 
 
 
 <Col md={{ span: 2, offset: 3}}  className="d-none d-lg-block" >
 
 <Row>
 
 
 
 <Col md={12} lg={12} xl={12}  style={{ 
    display: "flex",  justifyContent: "flex-end",
    }} >
  
 
  <div style={{
          //  border:"2px solid green",
              paddingRight:"12px", 
              paddingLeft:"24px"
            }} >
   <button 
 
 style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  border: "none",
  backgroundColor: "#101010",
  color: "#ffffff",
  opacity: "0.5",
  height:"37px",
  width:"37px"
 }}
 onClick={()=>setShowHelpModal(true)} 
    >
     < BiHelpCircle   style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />
 </button>
 
 </div>

 {projectType == "userGame"?(
   <div   style={{
    //  border:"2px solid green",
        paddingRight:"12px", 
        paddingLeft:"24px"
      }} >
   <button 
   // style={{borderRadius:"24px",background:"#36454F",opacity:"0.5",border:"2px solid #36454F",color:"#fff"}} 
   
   style={{
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     borderRadius: "50%",
     border: "none",
     backgroundColor: "#101010",
     color: "#ffffff",
     opacity: "0.5",
     height:"37px",
     width:"37px"
    
  }}
  
   onClick={()=>newBackCall()} >
   
 
           <FaArrowRightToCity    style={{
           color:"#ffffff",fontWeight: "bold",
            fontSize: "20px",
           }}  />
      
    </button>
</div>
):(null)}
 
  <div style={{
          //  border:"2px solid green",
              paddingRight:"12px", 
              paddingLeft:"24px"
            }} >
   <button 
 
 style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  border: "none",
  backgroundColor: "#101010",
  color: "#ffffff",
  opacity: "0.5",
  height:"37px",
  width:"37px"
 }}
 onClick={toggleMute} 
    >{!isMuted ? 
 
 < BsFillVolumeUpFill   style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />
 : 
 < HiSpeakerXMark style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />
 
 }
 </button>
 
 </div>
 
 
 <div   style={{
          //  border:"2px solid green",
              paddingRight:"12px", 
              paddingLeft:"24px"
            }} >
 
 <button 
  // style={{borderRadius:"24px",background:"#36454F",opacity:"0.5",border:"2px solid #36454F",color:"#fff"}} 
  
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "none",
    backgroundColor: "#101010",
    color: "#ffffff",
    opacity: "0.5",
    height:"37px",
    width:"37px"
   
 }}
 
  onClick={()=>screenSize()} >
     {isFullScreen ? 
         < IoMdContract style={{
          color:"#ffffff",fontWeight: "bold",
          fontSize: "20px",
          }}  /> 
       : 
          <GoScreenFull    style={{
          color:"#ffffff",fontWeight: "bold",
           fontSize: "20px",
          }}  />
     }
   </button>
   </div>


 

 
   </Col>
 
 </Row>
 
 </Col>
 
 </Row>
 ):(null)}
 
 
 <div  id="video-container"
        ref={videoContainerRef}
      
        
      />
       
      <audio id = "myAudio"/>


    </>

  );
}